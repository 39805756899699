import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import AboutDoc from "./AboutDoc";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { API_BASE_URL } from "../../environment.js/environment";
const Hero = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [resetElements, setResetElements] = useState(false);
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [showForm, setFormShow] = useState(false);
  const [data, setData] = useState([]);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_BASE_URL+"/api/Doctors/GetCountryCodes"); // Replace with your API URL
        const result = await response.json();
        debugger
        // Assuming the result is an array of objects with 'code' and 'country' properties
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    const validationErrors = validateForm();
    setErrors(validationErrors)
    debugger
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setDisable(false);
      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        setDisable(false);
        console.log('[error]', error);
        return
      } else {
        console.log('[PaymentMethod]', paymentMethod);
        setFormData((prevFormData) => ({
          ...prevFormData,
          'token': paymentMethod.id,
        }));
        debugger
        // Here you can call your backend endpoint to process the payment
      }
      console.log(formData);
      var formDataCopy = { ...formData, token: paymentMethod.id }
      setDisable(true);
      const response = await fetch(API_BASE_URL+"/api/Doctors/CreateDoctor", {
        method: 'POST',
        body: JSON.stringify(formDataCopy),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        if (response.status ==400) {
          var res =await response.json();
          toast.error(res.message);
          setDisable(false);
          return;
        }
        console.log(formData);
        toast.error('Error While Submitting the Application. Recheck your Information or Contact Custom Support!');
        setDisable(false);
      } else {
        // Handle success
        setResetElements(true);
        setTimeout(() => setResetElements(false), 0);
        toast.success('Successfully Registered!')
      
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          refNumber: "",
          country: "",
          password: "",
          cardHolderName: "",
          cardNumber: "",
          exp_Date: "",
          cVC: "",
          paymentFrom: "",
        });
        window.location.href='/login'
      }
      setTimeout(() => {
        setDisable(false);
      }, 3000);
    }
    else {
      setErrors(validationErrors);
      setDisable(false)
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    refNumber: "",
    country: "",
    password: "",
    password:'',
    cardHolderName: "",
    cardNumber: "",
    exp_Date: "",
    cVC: "",
    paymentFrom: "",
    token: '',
  });
  const handleCardChange = (e, field) => {
    const valueObjct = e.complete;
    if (valueObjct) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: true,
      }));
    }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: false,
      }));
    }
  }

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        border: "white",
        border: "1px solid white", // Adds a solid white border
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handlePasswordChange = (e) => {
    debugger
    const { value } = e.target;
    setFormData({ ...formData, password: value });
    setPasswordValidation({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  
    // Additional validation logic if needed
    if (value.trim() === "") {
      errors.password = "Password is required";
    }
    else{
      errors.password = null;
    }
    setErrors(errors);
  };
  const validateForm = () => {
    let validationErrors = {};

    // Validate first name
    if (formData.firstName.trim() === "") {
      validationErrors.firstName = "First name is required";
    }

    // Validate last name
    if (formData.lastName.trim() === "") {
      validationErrors.lastName = "Last name is required";
    }

    // Validate email
    if (formData.email.trim() === "") {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email format";
    }

    // Validate phone
    if (formData.phone.trim() === "") {
      validationErrors.phone = "Phone number is required";
    }
    if (formData.country.trim() === "") {
      validationErrors.country = "Country is required";
    }
    if (formData.password.trim() === "") {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one special character";
    }
    // Validate card number
    if (formData.cardNumber === "" || !formData.cardNumber) {
      validationErrors.cardNumber = "Card number is required";
    }

    // Validate expiration date
    if (formData.exp_Date === "" || !formData.exp_Date) {
      validationErrors.exp_Date = "Expiration date is required";
    }

    // Validate CVC
    if (formData.cVC === "" || !formData.cVC) {
      validationErrors.cVC = "CVC is required";
    }

    // Add more validation rules for other fields...

    return validationErrors;
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const stripe = useStripe();
  const elements = useElements();
  return (
    <>
      <section className="Hero">
      <ToastContainer />
        <div className="Background">
          <div className="Rectangle-One" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="Hero-Content wm-md-100 gap-24">
                <div className="Content">
                  <div>
                    <h4>
                      Global Doctors Association: Uniting Medical Excellence
                      Worldwide
                    </h4>
                  </div>
                  <div>
                    <p>
                    Our mission is to unite top medical minds, driving excellence and innovation in global patient care. Join us in improving health through expertise and dedication.
                    </p>
                  </div>
                </div>

                {/* <div className="bottom-content">
                  <div className="scrolldown">
                    <img src="/images/scrolldown.png" />
                    Scroll Down
                  </div>
                  <div>
                    <ul className="list-inline">
                      <li>
                        <Link to="">
                          <img src="/images/facebook.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/twit.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/tele.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/in.png" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="calculator">
                <div className="content" style={{position:'relative'}}>
                  <h2>Membership Registration</h2>
                  <div className="launch-price">
                    <h4>For nurses, physicians, and personal trainers.</h4>
                  </div>
                  <span style={{position:'absolute', right:"20px", top:'30px', cursor:'pointer'}} onClick={()=>{setFormShow(!showForm)}}>
                    {!showForm && <img alt="img" src="./images/dropdown-48.png" />}
                    {showForm && <img alt="img" src="./images/dropdown-48-down.png" />}
                    
                  </span>
                  {
                    showForm && <form className="calculateform">
                    <div className="form-group">
                      <div className="form">
                        <div>
                          <input
                            type="text"
                            className={`form-control ${errors.firstName ? "is-invalid" : ""
                            }`}
                            placeholder="First Name"
                             id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Last Name"
                            className={`form-control ${errors.lastName ? "is-invalid" : ""
                            }`}
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form">
                        <div>
                          <input
                            type="text"
                            placeholder="Email"
                            className={`form-control ${errors.email ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            value={formData.email}
                            autoComplete="new-email" 
                            onChange={handleChange}
                          />
                            {errors.email && errors.email.includes('Invalid') && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Phone"
                            className={`form-control ${errors.phone ? "is-invalid" : ""
                            }`}
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form">
                        <div className="select">
                          <select
                           className={`form-control ${errors.country ? "is-invalid" : ""
                           }`}
                           id="country"
                           name="country"
                           value={formData.country}
                           onChange={handleChange}
                           >
                            <option value="">Select Country</option>
                              {data.map((item) => (
                                <option key={item.code} value={item.code}>
                                  {item.country}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ref Number"
                          />
                        </div>
                      </div>
                      <div className="form">
                        <div className="password-wrapper" style={{ position: 'relative' }}>
                          <input
                           className={`form-control ${errors.password ? "is-invalid" : ""
                           }`}
                            id="password"
                            name="password"
                            value={formData.password}
                            autoComplete="new-password" 
                            onChange={handlePasswordChange}
                            type={passwordVisible ? "text" : "password"}
                            required
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                            title="Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
                          />
                            {errors.password  && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>)}
                          
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: errors.password ? '30%' :'50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer'
                            }}
                          >
                            {passwordVisible ?  (
                              <svg
                                fill="white"
                                width="25"
                                height="25"
                                viewBox="0 0 36 36"
                                version="1.1"
                                preserveAspectRatio="xMidYMid meet"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <title>eye-hide-solid</title>
                                <path
                                  d="M18.37,11.17A6.79,6.79,0,0,0,16,11.6l8.8,8.8A6.78,6.78,0,0,0,25.23,18,6.86,6.86,0,0,0,18.37,11.17Z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                ></path>
                                <path
                                  d="M34.29,17.53c-3.37-6.23-9.28-10-15.82-10a16.82,16.82,0,0,0-5.24.85L14.84,10a14.78,14.78,0,0,1,3.63-.47c5.63,0,10.75,3.14,13.8,8.43a17.75,17.75,0,0,1-4.37,5.1l1.42,1.42a19.93,19.93,0,0,0,5-6l.26-.48Z"
                                  className="clr-i-solid clr-i-solid-path-2"
                                ></path>
                                <path
                                  d="M4.87,5.78l4.46,4.46a19.52,19.52,0,0,0-6.69,7.29L2.38,18l.26.48c3.37,6.23,9.28,10,15.82,10a16.93,16.93,0,0,0,7.37-1.69l5,5,1.75-1.5-26-26Zm8.3,8.3a6.85,6.85,0,0,0,9.55,9.55l1.6,1.6a14.91,14.91,0,0,1-5.86,1.2c-5.63,0-10.75-3.14-13.8-8.43a17.29,17.29,0,0,1,6.12-6.3Z"
                                  className="clr-i-solid clr-i-solid-path-3"
                                ></path>
                                <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                              </svg>
                            ): (
                              <svg
                                fill="white"
                                width="25"
                                height="25"
                                viewBox="0 0 36 36"
                                version="1.1"
                                preserveAspectRatio="xMidYMid meet"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <title>eye-solid</title>
                                <path
                                  d="M33.62,17.53c-3.37-6.23-9.28-10-15.82-10S5.34,11.3,2,17.53L1.72,18l.26.48c3.37,6.23,9.28,10,15.82,10s12.46-3.72,15.82-10l.26-.48ZM17.8,26.43C12.17,26.43,7,23.29,4,18c3-5.29,8.17-8.43,13.8-8.43S28.54,12.72,31.59,18C28.54,23.29,23.42,26.43,17.8,26.43Z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                ></path>
                                <circle
                                  cx="18.09"
                                  cy="18.03"
                                  r="6.86"
                                  className="clr-i-solid clr-i-solid-path-2"
                                ></circle>
                                <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                              </svg>
                            )}

                          </span>
                         
                        </div>
                      </div>
                      { formData.password &&( <ul className="password-requirements">
                              <li style={{ color: passwordValidation.length ? "#17e517" : "red" }}>
                                At least 8 characters
                              </li>
                              <li style={{ color: passwordValidation.uppercase ? "#17e517" : "red" }}>
                                At least one uppercase letter
                              </li>
                              <li style={{ color: passwordValidation.lowercase ? "#17e517" : "red" }}>
                                At least one lowercase letter
                              </li>
                              <li style={{ color: passwordValidation.number ? "#17e517" : "red" }}>
                                At least one number
                              </li>
                              <li style={{ color: passwordValidation.specialChar ? "#17e517" : "red" }}>
                                At least one special character
                              </li>
                            </ul>)}
                      <div className="head d-flex align-items-center justify-content-between">
                        <div>
                          <span>
                            Credit Card Payment <b>Secured by Stripe</b>
                          </span>
                        </div>
                        <div>
                          <span>
                            <img
                              style={{ maxWidth: "40px" }}
                              src="./images/visa.jpg"
                              className="me-2"
                            />
                          </span>
                          <span>
                            <img
                              style={{ maxWidth: "40px" }}
                              src="./images/master.png"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row pt-0">
                        <div className="col-md-12">
                          <div class="form-heading">
                            <label>Expiration Date</label>
                          </div>

                          <div className="form">
                            <div  style={{
                          border: errors.cardNumber ? '1px solid red' : '1px solid rgba(217, 217, 217, 0.2)',
                          borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '10px'
                        }}>
                            <CardNumberElement
                           key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                           onChange={(e) => handleCardChange(e, 'cardNumber')}
                          />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-0">
                        <div className="col-md-6">
                          <div class="form-heading mt-0">
                            <label>Expiration Date</label>
                          </div>

                          <div className="form">
                          <div style={{
                              border: errors.exp_Date? '1px solid red' : '1px solid rgba(217, 217, 217, 0.2)',
                              borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '10px'
                            }}>
                            <CardExpiryElement
                                                              key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                                                              onChange={(e) => handleCardChange(e, 'exp_Date')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-heading mt-0">
                            <label>CVC</label>
                          </div>

                          <div className="form">
                            <div style={{
                              border: errors.cVC? '1px solid red' : '1px solid rgba(217, 217, 217, 0.2)',
                              borderRadius: '4px', padding: '10px', marginTop: '8px', marginBottom: '10px'
                            }}>
                            <CardCvcElement
                                key={resetElements ? 1 : 0} options={ELEMENT_OPTIONS}
                                onChange={(e) => handleCardChange(e, 'cVC')} 
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">

                          <div className="form">
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                placeholder="25$ monthly membership"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btn">
                      <button disabled={disable} type="button" className="btn form-control"  onClick={(e) => { handleSubmit(e) }}>
                        Submit
                      </button>
                    </div>
                  </form>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutDoc />
      </section>
    </>
  );
};

export default Hero;
